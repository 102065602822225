// axios封装第二层：账号管理模块，所有用户管理模块的接口全部在这里进行封装
import request from '@/utils/request.js'  //引入增强过后的axios

// 登录的接口函数(post方式)
export const doScanDomain = function (data) {
    return request({
        method: 'post',
        url: '/scanDomain/do',
        data: data,
    })
}
export const home = function (data) {
    return request({
        method: 'post',
        url: '/home',
        data: data,
    })
}
export const getUserInfo = function (data) {
    return request({
        method: 'post',
        url: '/getUserInfo',
        data: data,
    })
}
export const getHistory = function (data) {
    return request({
        method: 'post',
        url: '/getHistory',
        data: data,
    })
}
export const getTypeList = function (data) {
    return request({
        method: 'post',
        url: '/scanDomain/typeList',
        data: data,
    })
}
export const captcha = function (data) {
    return request({
        method: 'get',
        url: '/captcha1',
        data: data,
    })
}

export const login = function (data) {
    return request({
        method: 'post',
        url: '/login',
        data: data,
    })
}

export const register = function (data) {
    return request({
        method: 'post',
        url: '/register',
        data: data,
    })
}

export const updateUserInfo = function (data) {
    return request({
        method: 'post',
        url: '/updateUserInfo',
        data: data,
    })
}
export const realName = function (data) {
    return request({
        method: 'post',
        url: '/realName',
        data: data,
    })
}
export const sendEmail = function (data) {
    return request({
        method: 'post',
        url: '/sendEmail',
        data: data,
    })
}
export const updateEmail = function (data) {
    return request({
        method: 'post',
        url: '/updateEmail',
        data: data,
    })
}

export const sendPhoneCode = function (data) {
    return request({
        method: 'post',
        url: '/sendPhoneCode',
        data: data,
    })
}

export const suggestion = function (data) {
    return request({
        method: 'post',
        url: '/suggestion',
        data: data,
    })
}

export const reporting = function (data) {
    return request({
        method: 'post',
        url: '/reporting',
        data: data,
    })
}

export const getShopList = function (data) {
    return request({
        method: 'post',
        url: '/getShopList',
        data: data,
    })
}

export const order = function (data) {
    return request({
        method: 'post',
        url: '/order',
        data: data,
    })
}

export const exchangeShop = function (data) {
    return request({
        method: 'post',
        url: '/exchangeShop',
        data: data,
    })
}

export const cancelOrder = function (data) {
    return request({
        method: 'post',
        url: '/cancelOrder',
        data: data,
    })
}

export const getOrderList = function (data) {
    return request({
        method: 'post',
        url: '/getOrderList',
        data: data,
    })
}

export const forgetPassword = function (data) {
    return request({
        method: 'post',
        url: '/forgetPassword',
        data: data,
    })
}
